<template>
  <div class="mb-3 no-select">
    <v-card outlined :ripple="false" @click="is_expanded = !is_expanded">
      <v-card-text>
        <v-row>
          <v-col cols="10">
            <div v-if="titulo" class="titulo">{{titulo}}</div>
            <div v-if="subtitulo" class="subtitulo">{{subtitulo}}</div>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-end">
            <v-progress-circular
              v-show="loading"
              :width="2"
              :size="20"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <v-icon class="ml-4">{{ is_expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-expand-transition mode="in-out">
      <div v-show="is_expanded">
        <slot></slot>
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup>

import { ref, watch } from "vue";

const props = defineProps({
  expanded: {
    type: Boolean,
    default: false
  },

  titulo: {
    type: String,
    default: ''
  },

  subtitulo: {
    type: String,
    default: ''
  },

  loading: {
    type: Boolean,
    default: false
  },
});

const is_expanded = ref(props.expanded);

watch(() => props.expanded, (newValue, oldValue) => {
  is_expanded.value = newValue;
})

</script>

<style lang="scss" scoped>
  .titulo {
    font-weight: 600;
  }

  .subtitulo {
    // font-size: 0.7rem !important;
    font-weight: normal;
    line-height: 1rem;
    letter-spacing: 0;
  }

  .no-select {
    user-select: none;
  }
</style>
